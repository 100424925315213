<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">{{ dt.id }}</a>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.CoinType.name }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.CurrencyFiat.name }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.CurrencyFiat.code }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.rate }}</span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{
          dt.minimum_usd_value
          }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{
          dt.maximum_usd_Value
          }}
        </span>
      </td>

      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <!-- <router-link
                :to="`/coins/${dt.id}/entries?id=${dt.id}`"
                class="dropdown-item text-dark"
              >
                <span class="fas fa-eye mr-1"></span>
                See Entries
            </router-link>-->
            <!-- <router-link :to="`coin-rates/${dt.id}`" class="dropdown-item text-dark">
                <span class="fas fa-eye mr-1"></span>
                See Details
            </router-link>-->

            <router-link
              :to="`/coins/entries/${dt.coin_type_id}/rate/${dt.id}`"
              class="dropdown-item text-dark"
            >
              <span class="fas fa-eye mr-1"></span>
              Edit Entries
            </router-link>
            <a
              href="#"
              class="dropdown-item text-danger"
              @click="
                RequestConfirmation(
                  'Are you sure you want to delete this entry? This action is irreversible',
                  ()=> {

                    DeleteEntry(dt.id)
                  }
                )
              "
            >
              <span class="fas fa-trash-alt mr-1"></span>
              Delete Entry
            </a>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>
  
  <script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiPost, apiDelete } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "CoinRateDetailRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete"],

  setup(props, context) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.type =
          props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const DeleteEntry = async (id: string) => {
      loading.value = true;
      try {
        await apiDelete(`/admin/coin/rate/${id}`);
        context.emit("delete");

        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      ToggleAdmin,
      DeleteEntry,
    };
  },
});
</script>
  
  <style scoped></style>
  